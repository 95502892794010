@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.ui.cards.detail-card {
  &.hide-card {
    display: none;
  }

  .mobile-card-highlighted {
    color: $color-light-blue13;
  }

  .detailed-card-name-read-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.ui.card {
    width: 100% !important;
    & > div.content {
      & > h5 {
        font-family: FuturaStd-Heavy;
        font-weight: normal;
        margin: 0;
        line-height: 20px;

        &.lighter {
          font-family: FuturaStd-Medium;
          width: 82%;
        }
      }

      & > div.column > div.column {
        padding-top: 3px;
        padding-bottom: 2px;
      }

      .ui.grid {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;

        .column {
          padding-left: 0;

          &.bold-text {
            font-family: FuturaStd-Heavy;
          }
        }
      }

      &.icon-row-container {
        overflow-x: auto;
        max-height: 95px;
        justify-content: space-between;

        & > div.icon-row {
          display: flex;
          flex-direction: row;

          div.index-table-icon-action {
            max-width: fit-content;
          }
        }
      }
    }

    button.ui.button.circular-button-expand {
      margin-top: -40px;
      position: absolute;
      right: 5%;
      border-radius: 50%;
      background-color: $color-text-alternative0;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      height: 42px;
      width: 42px;

      & > svg {
        margin-left: -18px;
        margin-top: -7px;
        color: $color-light-blue0;
        width: 35px;
        height: 35px;
      }
    }

    .bold-item {
      font-family: FuturaStd-Heavy;
    }

    .edit-content {
      display: flex;
      flex-direction: column;

      > .ui.radio.checkbox {
        margin-bottom: 5px;

        > input {
          color: $color-light-blue0;
        }
      }
    }
  }
}
